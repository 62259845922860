import React from "react";
import TopMenu from "./components/TopMenu";
import Footer from "../shared/Footer";
import {makeStyles} from "@material-ui/core/styles";
import Calendy from "../shared/Calendy";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    grow: {
        background: "white",
        minWidth: "100px",
        flex: 1
    }
})

function Layout(props) {

    const classes = useStyles();

    return (
        <>
            <div className={classes.container}>
                <div>
                    <TopMenu setLanguage={props.setLanguage}/>
                    <div className={classes.content}>
                        {props.children}
                    </div>
                    <div className={classes.grow}/>
                </div>
                <Calendy/>
                <Footer/>
            </div>
        </>
    )
}

export default Layout;