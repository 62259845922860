import React, {createContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";


export const LanguageContext = createContext({language: "fr"});


const LanguageProvider = (props) => {

    const [language, setLanguage] = useState(props.language);


    useEffect(() => {
        setLanguage(props.value);
    }, [props.value]);

    return (
        <LanguageContext.Provider value={language}>
            {props.children}
        </LanguageContext.Provider>
    );

}

export default withRouter(LanguageProvider);
