import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({});

function Webinar() {

    const classes = useStyles();

    return (
        <div className={classes.content}>
            Available soon.
        </div>)
}

export default Webinar;