import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {PayPalButton} from "react-paypal-button-v2";
import TextField from "@material-ui/core/TextField";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles({
    payment: {
        height: '100%',
        margin: "50px",
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignItems: 'center',
    },
    container: {
        marginTop: "100px",
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignItems: 'center',
    },
    title: {
        margin: '20px'
    },
    fields: {
        display: 'flex',
        flexDirection: 'column'
    },
    field: {
        margin: '10px'
    },
    orderInfo: {
        width: '50%',
        margin: '10px'
    },
    confirmMessage: {
        color: "green"
    },
    grow: {
        flex: 1,
    }
});

function Payment() {
    const classes = useStyles();

    const [amount, setAmount] = React.useState("0");
    const [name, setName] = React.useState("");
    const [invoice, setInvoice] = React.useState("");
    const [orderId, setOrderId] = React.useState(false);


    return (
        <div className={classes.container}>
            <Typography className={classes.title} variant={'h4'}>Effectuer un paiement</Typography>
            <Paper elevation={3} className={classes.payment}>
                <div className={classes.fields}>

                    <TextField
                        className={classes.field}
                        id={"name"}
                        variant="outlined"
                        label={"Nom Complet"}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                    <TextField
                        className={classes.field}
                        id={"numero"}
                        variant="outlined"
                        label={"# de la facture"}
                        value={invoice}
                        onChange={(event) => setInvoice(event.target.value)}
                    />
                    <CurrencyTextField
                        className={classes.field}
                        label="Montant de la facture"
                        variant="outlined"
                        value={amount}
                        currencySymbol="$"
                        outputFormat="string"
                        onChange={(event, data) => setAmount(data)}
                    />
                </div>
                <PayPalButton
                    amount="0.01"
                    currency={"CAD"}
                    shippingPreference={"NO_SHIPPING"}
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    currency_code: "CAD",
                                    value: amount
                                },
                                invoice_id: invoice,
                                description: name
                            }],
                        });
                    }}
                    onSuccess={(details, data) => {
                        setOrderId(details.id);
                    }}
                    options={{
                        currency: "CAD",
                        clientId: "AR6oH0MpumvIzZ52KNxj1b2zF_9C_0fktuYRuAcrccKboGuP_GM_yLHy44sdhqbCimvII22nyA-Mm-Tc"
                    }}
                />
            </Paper>

            <Paper className={classes.orderInfo}>
                {orderId && <Typography variant={'h4'} className={classes.confirmMessage}>
                    {"Votre paiement a été approuvé! Le # de la commande est le " + orderId + "."}
                </Typography>}
            </Paper>
            <div className={classes.grow}/>
        </div>

    )
}

export default Payment;