import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../../static/logo.png'
import Typography from "@material-ui/core/Typography";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import {useHistory, withRouter} from "react-router-dom";
import {LanguageContext} from "../../LanguageProvider";
import text from "../../static/text.json"
import Button from "@material-ui/core/Button";


const useStyles = makeStyles(theme => ({
    appBar: {
        flexDirection: 'row',
        background: '#ffffff',
        color: '#1b5c7f',
        alignItems: 'center'
    },
    label: {
        marginTop: "30px"
    },
    tabs: {
        [theme.breakpoints.down("md")]: {
            display: "none"
        },
        height: "100%",
        '& .MuiTabs-indicator': {
            marginTop: "10px",
            height: "2px",
            background: '#FFC000',
            marginBottom: '1px'
        },
        '& .MuiTabs-scrollButtons': {
            marginTop: "30px",
        }
    },
    drawer: {
        height: "100%",
        width: "100%",
        marginRight: "150px",
        '& .MuiTabs-indicator': {
            margin: "5px",
            background: '#232323',
        }
    },
    grow: {
        flex: 1
    },
    logo: {
        [theme.breakpoints.down("md")]: {
            marginLeft: '0%',
        },
        width: "340px",
        marginLeft: '10%'
    },
    icon: {
        width: "100px",
        height: "100px",
    },
    menu: {
        marginLeft: "8%",
        display: "none",
        [theme.breakpoints.down("md")]: {
            display: "inline"
        }
    },
    languageButton: {
        border: "0px",
        padding: "0px",
        minWidth: "0px",
        color: '#1b5c7f',
    },
    selectedLanguageButton: {
        border: "0px",
        padding: "0px",
        minWidth: "0px",
        color: 'rgb(217,169,0)',
    },
    language: {
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        justifyItems: "center",
        marginTop: "30px",
        marginRight: "10%"
    }
}));


function TopMenu(props) {

    const classes = useStyles();
    const history = useHistory();
    const [index, setIndex] = React.useState(0);
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const language = React.useContext(LanguageContext)

    const englishButtonClassName = language === "en" ? classes.selectedLanguageButton : classes.languageButton
    const frenchButtonClassName = language === "fr" ? classes.selectedLanguageButton : classes.languageButton

    React.useEffect(() => {
        /*        const viewport = document.querySelector('meta[name="viewport"]');
                if (viewport) {
                    viewport.content = 'initial-scale=1';
                    viewport.content = 'width=device-width';
                }*/
        setIndex(urlToIndex());
    }, [props.location.pathname]);

    function urlToIndex() {
        const path = props.location.pathname;
        if (path === "/about") {
            return 1;
        } else if (path === "/services") {
            return 2;
        } else if (path === "/resources") {
            return 3;
        } else if (path === "/contact") {
            return 4;
        } else if (path === "/billing") {
        } else {
            return 0
        }
    }

    function handleChange(event, value) {
        setOpenDrawer(false);
        setIndex(value);
        if (value === 0) {
            history.push("/home")
        } else if (value === 1) {
            history.push("/about")
        } else if (value === 2) {
            history.push("/services")
        } else if (value === 3) {
            history.push("/resources")
        } else if (value === 4) {
            history.push("/contact")
        } else if (value === 5) {
            history.push("/billing")
        } else if (value === 6) {
            history.push("/rates")
        }
    }

    return (
        <>
            <AppBar elevation={0} className={classes.appBar} position="sticky">
                <IconButton onClick={() => setOpenDrawer(!openDrawer)} className={classes.menu}>
                    <MenuIcon fontSize={"large"} className={classes.icon}/>
                </IconButton>
                <div className={classes.logo}>
                    <img width='100%' src={logo} alt={""}/>
                </div>
                <div className={classes.grow}/>
                <Tabs className={classes.tabs}
                      value={index}
                      variant="scrollable"
                      scrollButtons="on"
                      onChange={handleChange}>
                    <Tab label={<Typography className={classes.label}
                                            variant={"h6"}>{text.homeTab[language]}</Typography>} id={0}/>
                    <Tab label={<Typography className={classes.label}
                                            variant={"h6"}>{text.aboutTab[language]}</Typography>} id={1}/>
                    <Tab label={<Typography className={classes.label}
                                            variant={"h6"}>{text.serviceTab[language]}</Typography>} id={2}/>
                    <Tab label={<Typography className={classes.label}
                                            variant={"h6"}>{text.resourceTab[language]}</Typography>} id={3}/>
                    <Tab label={<Typography className={classes.label}
                                            variant={"h6"}>{text.contactTab[language]}</Typography>} id={4}/>
                    id={5}/>
                </Tabs>
                <div className={classes.grow}/>
                <div className={classes.language}>
                    <Button className={classes.languageButton}>
                        <Typography className={frenchButtonClassName}
                                    onClick={() => props.setLanguage("fr")}>FR</Typography>
                    </Button>
                    <Typography>/</Typography>
                    <Button className={englishButtonClassName}>
                        <Typography onClick={() => props.setLanguage("en")}>EN</Typography>
                    </Button>
                </div>
            </AppBar>
            <SwipeableDrawer anchor={"left"} open={openDrawer} onOpen={() => setOpenDrawer(true)}
                             onClose={() => setOpenDrawer(false)}>
                <Tabs className={classes.drawer} value={index} orientation={"vertical"} onChange={handleChange}>
                    <Tab label={<Typography className={classes.label}
                                            variant={"h5"}>{text.homeTab[language]}</Typography>} id={0}/>
                    <Tab label={<Typography className={classes.label}
                                            variant={"h5"}>{text.aboutTab[language]}</Typography>} id={1}/>
                    <Tab label={<Typography className={classes.label}
                                            variant={"h5"}>{text.serviceTab[language]}</Typography>} id={2}/>

                    <Tab label={<Typography className={classes.label}
                                            variant={"h5"}>{text.resourceTab[language]}</Typography>} id={3}/>
                    <Tab label={<Typography className={classes.label}
                                            variant={"h5"}>{text.contactTab[language]}</Typography>} id={4}/>
                    <Tab label={<Typography className={classes.label}
                                            variant={"h5"}>{text.onlinePaymentTab[language]}</Typography>}
                         id={5}/>
                    <Tab label={<Typography className={classes.label}
                                            variant={"h5"}>{text.ratesTab[language]}</Typography>}
                         id={6}/>
                </Tabs>
            </SwipeableDrawer>
        </>

    );
}

export default withRouter(TopMenu);