import React from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles({
    footer: {
        width: '100%',
        background: '#ffffff'
    }

});


function Footer(props) {

    const classes = useStyles();

    return <div className={classes.footer}><Typography>©2022 Ariane Somma</Typography></div>
}

export default Footer;