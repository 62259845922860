import React from "react";
import ContactForm from "./components/ContactForm";
import GoogleMapReact from 'google-map-react';
import {makeStyles} from "@material-ui/core/styles";
import BusinessIcon from '@material-ui/icons/Business';
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {LanguageContext} from "../LanguageProvider";
import text from "../static/text.json"


const useStyles = makeStyles({
    content: {
        marginTop: "50px",
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    map: {
        marginTop: "50px",
        width: '200px',
        height: '200px',
    },
    mapIcon: {
        position: 'absolute',
        transform: 'translate(-50%, -50%)'
    },
    schedule: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '70%',
        padding: '50px',
        margin: '50px'
    },
    grow: {
        flex: 1,
    }

});

function Contact(props) {

    const classes = useStyles();
    const language = React.useContext(LanguageContext)


    const Marker = props => (
        <IconButton className={classes.mapIcon}
                    href={"https://www.google.ca/maps/place/3107+Avenue+des+H%C3%B4tels,+Qu%C3%A9bec,+QC+G1W+4W5/@46.7628223,-71.2947326,17z/data=!3m1!4b1!4m5!3m4!1s0x4cb8912fb465dd57:0xe57929642244ff8b!8m2!3d46.7628223!4d-71.2947326"}
                    target={"_blank"}>
            <BusinessIcon fontSize={"large"}></BusinessIcon>
        </IconButton>
    );

    const center = {lat: 46.76, lng: -71.29450729629512};
    return (
        <div className={classes.content}>
            <div style={{height: '400px', width: '80%'}}>
                <GoogleMapReact
                    resetBoundsOnResize={true}
                    bootstrapURLKeys={{key: "AIzaSyBGazUrLUQoyVeVufgqKtiS3BG8mu_C0OU"}}
                    defaultCenter={center}
                    defaultZoom={12}
                    style={{height: '100%', width: '100%'}}
                >
                    <Marker lat={46.763083184577965} lng={-71.29450729629512}/>
                </GoogleMapReact>
            </div>
            <div>
                <Paper className={classes.schedule} elevaion={3}>
                    <Typography variant={'h5'}>{text.contactPage[language]}</Typography>
                    <hr width={'100%'} color={'black'}/>
                    <Typography variant={'h6'}><b>{text.monday[language]}:</b> 8h30 - 20h00 </Typography>
                    <Typography variant={'h6'}><b>{text.tuesday[language]}:</b> 8h30 - 20h00 </Typography>
                    <Typography variant={'h6'}><b>{text.wednesday[language]}:</b> 8h30 - 20h00 </Typography>
                    <Typography variant={'h6'}><b>{text.thursday[language]}:</b> 8h30 - 20h00 </Typography>
                    <Typography variant={'h6'}><b>{text.friday[language]}:</b> 8h30 - 20h00 </Typography>
                    <Typography variant={'h6'}><b>{text.saturday[language]}:</b> 8h30 - 20h00 </Typography>
                    <Typography variant={'h6'}><b>{text.sunday[language]}:</b> {text.closed[language]} </Typography>
                </Paper>
            </div>

            <ContactForm/>
        </div>)
}

export default Contact;