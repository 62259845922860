import React from "react";
import ContactForm from './components/ContactForm'
import image_1 from '../static/hand.png'
import image_2 from '../static/house.png'
import image_3 from '../static/diagram.png'
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from '@material-ui/core/Link';
import {useHistory} from "react-router-dom";
import {LanguageContext} from "../LanguageProvider";
import text from "../static/text.json"


const useStyles = makeStyles({
    service: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    section: {
        marginTop: "20px",
        marginBottom: "20px",
        background: "white",
        height: '100%',
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '20px'
    },
    textContent: {
        marginTop: "20px",
        marginBottom: "20px",
        background: "white",
        height: '100%',
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '20px'
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    image: {
        width: '65%',
        margin: '25px',
        borderRadius: '2%'
    },
    grow: {
        flex: 1,
    },
    button: {
        marginTop: "20px",
        background: '#fdbf01',
        width: '60%'
    },
    title: {
        color: '#4472c3'
    },
    textOver: {
        lineHeight: '100%'
    },
    textSpace: {
        paddingTop: '2%'
    },
    Link: {
        color: '#fdbf01',
        fontWeight: 'bold',
        textDecoration: 'underline'
    },
});

function Service(props) {
    const classes = useStyles();
    const history = useHistory();
    const language = React.useContext(LanguageContext)


    function scrollToBottom() {
        window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'})
    }

    return (

        <div>
            <div className={classes.service}>
                <Paper className={classes.section}>
                    <img className={classes.image} src={image_1}/>
                    <div className={classes.textContent}>

                        <Typography align={'center'} className={classes.title} variant={'h4'}>
                            {text.servicePageSectionATitle[language]}
                        </Typography>

                        <Typography variant={'h6'}>
                            <div className={classes.textOver}>
                                <Typography variant={'h5'} className={classes.textSpace}>
                                    {text.servicePageSectionATextA[language]}<br/>
                                </Typography>
                                <Typography variant={'h5'} className={classes.textSpace}>
                                    {text.servicePageSectionATextB[language]}
                                </Typography>
                                <Typography variant={'h5'} className={classes.textSpace}>
                                    {text.servicePageSectionATextC[language]}<br/>
                                </Typography>
                                <Typography variant={'h5'} className={classes.textSpace}>
                                    {text.servicePageSectionATextD[language]}
                                    <Link className={classes.Link} onClick={() => {
                                        history.push("/resources")
                                    }}>{text.servicePageSectionALink[language]}</Link>
                                </Typography>
                            </div>
                        </Typography>
                    </div>
                </Paper>
                <Paper className={classes.section}>
                    <img className={classes.image} src={image_2}/>
                    <div className={classes.textContent}>
                        <div className={classes.title}>
                            <Typography align={'center'} variant={'h4'}>
                                {text.servicePageSectionBTitle[language]}
                            </Typography>
                        </div>
                        <div className={classes.list}>
                            <Typography variant={'h6'}>
                                <div className={classes.textOver}>
                                    <Typography variant={'h5'} className={classes.textSpace}>
                                        {text.servicePageSectionBTextA[language]}
                                    </Typography>
                                    <Typography variant={'h5'} className={classes.textSpace}>
                                        {text.servicePageSectionBTextB[language]}
                                    </Typography>
                                </div>
                            </Typography>
                        </div>
                        {language === "fr" &&
                        <Button className={classes.button} onClick={scrollToBottom} color='primary' variant='contained'>
                            <Typography variant={'h5'}>{text.contactUsButton[language]}</Typography></Button>}
                    </div>
                </Paper>
                {language === "fr" && <Paper className={classes.section}>
                    <div className={classes.title}>
                        <Typography align={'center'} variant={'h4'}>
                            LE PROCESSUS DE MÉDIATION FAMILIALE
                        </Typography>
                    </div>
                    <img className={classes.image} src={image_3}/>
                </Paper>}
                <div className={classes.grow}/>
                <ContactForm/>
            </div>
        </div>
    )
}

export default Service;