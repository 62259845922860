import React from "react";
import Button from "@material-ui/core/Button";
import firebase from '../../firestore';
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import image_1 from '../../static/contact.png'
import Paper from "@material-ui/core/Paper";
import {LanguageContext} from "../../LanguageProvider";
import text from "../../static/text.json"


const useStyles = makeStyles(theme => ({
    outer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
        minHeight: '450px',
        width: '80%',
        background: 'white'
    }, inner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '80%',
        width: '100%',
        color: 'white',
        borderRadius: '10px',
        overflow: "hidden",
        background: '#4472c3'
    }, innerContent: {
        marginTop: '40px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down("md")]: {
            flexDirection: 'column',
            alignItems: 'center'
        },
    }, staticContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center'
    }, staticSection: {
        display: 'flex',
        width: '65%',
        [theme.breakpoints.down("md")]: {
            width: '100%',
        },
        flexDirection: 'row',
        alignItems: 'center'
    },
    staticFields: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }, fields: {
        margin: '20px',
        width: '50%',
        [theme.breakpoints.down("md")]: {
            width: '100%',
        },
        display: 'flex',
        flexDirection: 'row',
        background: 'white'
    }, otherFields: {
        margin: '20px',
        width: '35%',
        display: 'flex',
        flexDirection: 'column',
        background: 'white'
    }, messageBox: {
        margin: '20px',
        width: '60%',
        [theme.breakpoints.down("md")]: {
            width: '50%',
        },
        display: 'flex',
        flexDirection: 'column',
        background: 'white'
    }, button: {
        background: '#fdbf01',
        width: '100%',
        marginTop: '10px'
    },
    image: {
        display: 'block',
        maxWidth: '230px',
        width: 'auto',
        height: 'auto',
        margin: '20px',
        borderRadius: '2%',
    },

}));


function ContactForm(props) {

    const classes = useStyles();
    const language = React.useContext(LanguageContext)
    const db = firebase.firestore();

    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [sent, setSent] = React.useState("");

    React.useEffect(() => {
        if (sent !== "Merci!") {
            setSent(text.contactFormSend[language]);
        }
    }, [language]);


    async function sendEmail() {
        if (sent === text.contactFormSend[language]) {
            await db.collection('mail').add({
                to: ['as@asavocat.ca'],
                message: {
                    subject: 'New Contact',
                    html: `You have received a contact request from ${name}. Phone: ${phone} Email: ${email} Message: ${message}`
                }
            });
            setSent("Merci!")
        }
    }

    return (
        <div className={classes.outer}>
            <Paper elevation={3} className={classes.inner}>
                <div className={classes.innerContent}>
                    <div className={classes.staticSection}>
                        <img className={classes.image} src={image_1} alt={""}/>
                        <div className={classes.staticContent}>
                            <div className={classes.staticFields}>
                                <Typography align={'center'}
                                            variant={'h4'}>{text.contactFormTitle[language]}</Typography>
                                <hr width={'100%'}/>
                                <Typography variant={'h6'}>3107 avenue des Hôtels</Typography>
                                <Typography variant={'h6'}>Québec (QC) G1W 4W5</Typography>
                                <Typography variant={'h6'}>{text.contactFormEmailAcronym[language]}:
                                    as@asavocat.ca </Typography>
                                <Typography variant={'h6'}>{text.contactFormPhoneAcronym[language]}: <a
                                    style={{color: '#FFF'}}
                                    href="tel:581-777-5903">581-777-5903</a></Typography>
                                <Typography variant={'h6'}>F: 418 425-0678</Typography>
                            </div>
                        </div>
                    </div>
                    <div className={classes.fields}>
                        <div className={classes.otherFields}>
                            <TextField spellCheck="false" label={text.contactFormName[language]} value={name}
                                       onChange={(event) => setName(event.target.value)}/>
                            <TextField spellCheck="false" label={text.contactFormEmail[language]} value={email}
                                       onChange={event => setEmail(event.target.value)}/>
                            <TextField spellCheck="false" label={text.contactFormPhone[language]} value={phone}
                                       onChange={event => setPhone(event.target.value)}/>
                        </div>
                        <div className={classes.messageBox}>
                            <TextField multiline
                                       spellCheck="false"
                                       variant={'outlined'}
                                       rows={7}
                                       value={message}
                                       onChange={event => setMessage(event.target.value)}
                                       placeholder={text.contactFormMessage[language]}/>

                            <Button className={classes.button} onClick={sendEmail} color='primary'
                                    variant='contained'> {sent}</Button>
                        </div>
                    </div>
                </div>
            </Paper>
        </div>
    )
}

export default ContactForm;