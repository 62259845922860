import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import portrait from "../static/portrait.png"
import ContactForm from "./components/ContactForm";
import {LanguageContext} from "../LanguageProvider";
import text from "../static/text.json"


const useStyles = makeStyles(theme => ({
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    missionSection: {
        margin: '50px',
        width: '80%'

    },
    card: {
        background: '#4472c3',
        minHeight: '334px',
        display: 'flex',
        width: '80%',
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down("md")]: {
            flexDirection: 'column'
        }
    },
    contentContainer: {
        width: '100%',
        height: '100%',
        background: '#4472c3',
    },
    cardContent: {
        marginTop: '10%',
        margin: '30px',
        color: 'white'
    },
    paragraph: {
        margin: '50px'
    },

    image: {
        display: 'block',
        maxWidth: '600px',
        width: 'auto',
        minHeight: 'auto',
    },
    title: {
        color: '#4472c3'
    }
}));

function About() {
    const classes = useStyles();
    const language = React.useContext(LanguageContext)

    return (
        <div className={classes.content}>
            <Paper elevaion={3} className={classes.card}>
                <div>
                    <img className={classes.image} src={portrait}/>
                </div>
                <div className={classes.contentContainer}>
                    <div className={classes.cardContent}>
                        <Typography variant={'h3'} gutterBottom
                                    align={'center'}> {text.aboutPageSectionATitle[language]}</Typography>
                        <hr color={"white"} width={"80%"}/>
                        <Typography className={classes.paragraph} variant={'h5'} gutterBottom
                                    align={'justify'}> {text.aboutPageSectionATextA[language]}</Typography>
                        <Typography className={classes.paragraph} variant={'h5'} gutterBottom
                                    align={'justify'}> {text.aboutPageSectionATextB[language]}</Typography>
                        <Typography className={classes.paragraph} variant={'h5'} gutterBottom
                                    align={'justify'}> {text.aboutPageSectionATextC[language]}</Typography>
                    </div>
                </div>
            </Paper>
            <Paper className={classes.missionSection}>
                <Typography className={classes.title} variant={'h4'} gutterBottom
                            align={'center'}> {text.aboutPageSectionBTitle[language]}</Typography>
                <hr color={"black"} width={"80%"}/>
                <Typography className={classes.paragraph} variant={'h5'} gutterBottom
                            align={'justify'}> {text.aboutPageSectionBTextA[language]}</Typography>
                <Typography className={classes.paragraph} variant={'h5'} gutterBottom
                            align={'justify'}> {text.aboutPageSectionBTextB[language]}</Typography>
                <Typography className={classes.paragraph} variant={'h5'} gutterBottom
                            align={'justify'}> {text.aboutPageSectionBTextC[language]}</Typography>

            </Paper>
            <ContactForm/>
        </div>
    )
}

export default About;