import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyALxDUWCouZ6KjB8WHwAkZHG1JnQErvwwU",
    authDomain: "asavocat-776b1.firebaseapp.com",
    databaseURL: "https://asavocat-776b1.firebaseio.com",
    projectId: "asavocat-776b1",
    storageBucket: "asavocat-776b1.appspot.com",
    messagingSenderId: "117163675818",
    appId: "1:117163675818:web:952cf30e20776f625540d3"
};

firebase.initializeApp(config);
export default firebase;