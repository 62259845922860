import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {LanguageContext} from "../LanguageProvider";
import text from "../static/text.json"


const useStyles = makeStyles({
    resource: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    section: {
        marginTop: "20px",
        marginBottom: "20px",
        background: "white",
        height: '100%',
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    textContainer: {
        width: '100%',
        marginLeft: '100px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flexStart'
    },
    textSpace: {
        paddingBottom: '2%'
    },
    Link: {
        color: '#1b5c7f',
        fontWeight: 'bold',
        textDecoration: 'underline'
    },
});

function Resource() {
    const classes = useStyles();
    const language = React.useContext(LanguageContext)

    return (
        <div className={classes.resource}>
            <Paper elevation={3} className={classes.section}>
                <Typography variant={'h3'}>{text.resourcePageTitle[language]}</Typography>
                <hr color={"black"} width={"80%"}/>
                <div className={classes.textContainer}>

                    <Typography align={'left'} variant={'h4'}>{text.resourcePageSubtitleA[language]}</Typography>
                    <Typography className={classes.textSpace} align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={text.resourcePageSubtitleAhref[language]}>
                            www.justice.gouv.qc.ca
                        </Link>
                    </Typography>

                    <Typography align={'left'} variant={'h4'}>{text.resourcePageSubtitleB[language]}</Typography>
                    <Typography className={classes.textSpace} align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={text.resourcePageSubtitleBhref[language]}>
                            www.educaloi.qc.ca
                        </Link>
                    </Typography>

                    <Typography align={'left'} variant={'h4'}>{text.resourcePageSubtitleC[language]}</Typography>
                    <Typography className={classes.textSpace} align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={text.resourcePageSubtitleChref[language]}>
                            {text.resourcePageSubtitleCalias[language]}
                        </Link>
                    </Typography>

                    <Typography align={'left'} variant={'h4'}>{text.resourcePageSubtitleD[language]}</Typography>
                    <Typography className={classes.textSpace} align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={text.resourcePageSubtitleDhref[language]}>
                            {text.resourcePageSubtitleDalias[language]}
                        </Link>
                    </Typography>

                    <Typography align={'left'} variant={'h4'}>{text.resourcePageSubtitleE[language]}</Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={text.resourcePageSubtitleEhrefA[language]}>
                            {text.resourcePageSubtitleEaliasA[language]}
                        </Link>
                    </Typography>
                    <Typography className={classes.textSpace} align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={text.resourcePageSubtitleEhrefB[language]}>
                            {text.resourcePageSubtitleEaliasB[language]}
                        </Link>
                    </Typography>

                    <Typography align={'left'} variant={'h4'}>{text.resourcePageSubtitleF[language]}</Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={text.resourcePageSubtitleFhrefA[language]}>
                            {text.resourcePageSubtitleFaliasA[language]}
                        </Link>
                    </Typography>
                    <Typography className={classes.textSpace} align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={text.resourcePageSubtitleFhrefB[language]}>
                            {text.resourcePageSubtitleFaliasB[language]}
                        </Link>
                    </Typography>

                    <Typography align={'left'} variant={'h4'}>{text.resourcePageSubtitleG[language]}</Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={text.resourcePageSubtitleGhrefA[language]}>
                            {text.resourcePageSubtitleGaliasA[language]}
                        </Link>
                    </Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={text.resourcePageSubtitleGhrefB[language]}>
                            {text.resourcePageSubtitleGaliasB[language]}
                        </Link>
                    </Typography>
                    <Typography className={classes.textSpace} align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={text.resourcePageSubtitleGhrefC[language]}>
                            {text.resourcePageSubtitleGaliasC[language]}
                        </Link>
                    </Typography>

                    <Typography align={'left'} variant={'h4'}>{text.resourcePageSubtitleH[language]}</Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={text.resourcePageSubtitleHhrefA[language]}>
                            {text.resourcePageSubtitleHaliasA[language]}
                        </Link>
                    </Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={text.resourcePageSubtitleHhrefB[language]}>
                            {text.resourcePageSubtitleHaliasB[language]}
                        </Link>
                    </Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={text.resourcePageSubtitleHhrefC[language]}>
                            {text.resourcePageSubtitleHaliasC[language]}
                        </Link>
                    </Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={text.resourcePageSubtitleHhrefD[language]}>
                            {text.resourcePageSubtitleHaliasD[language]}
                        </Link>
                    </Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href={text.resourcePageSubtitleHhrefE[language]}>
                            {text.resourcePageSubtitleHaliasE[language]}
                        </Link>
                    </Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://www.relevaillesquebec.com/">
                            Groupe les relevailles
                        </Link>
                    </Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://www.horizonnouveau.org/">
                            Horizon nouveau
                        </Link>
                    </Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href="http://www.ifacef.com/services/">
                            IFACEF
                        </Link>
                    </Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://sites.google.com/site/joujouthequebasseville/">
                            Joujouthèque basse-ville
                        </Link>
                    </Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://www.mf-quebec.org/">
                            Maison de la famille de Québec
                        </Link>
                    </Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href="http://lamaisonouverte.ca/">
                            Maison ouverte
                        </Link>
                    </Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://lepetitrepit.ca/index.php/fr/">
                            Le petit répit
                        </Link>
                    </Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://www.arrondissement.com/capitale_nationale-list-bottin/s34-soutien-ressources-pour-familles/">
                            Arrondissement.com
                        </Link>
                    </Typography>
                    <Typography align={'left'} variant={'h5'}>
                        <Link
                            className={classes.Link}
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://alienationparentale.ca/fr/">
                            Carrefour aliénation parentale
                        </Link>
                    </Typography>
                </div>
            </Paper>
        </div>

    )
}

export default Resource;