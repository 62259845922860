import React from "react";
import ContactForm from './components/ContactForm'
import image_1 from '../static/image_1.jpg'
import image_3 from '../static/image_3.jpg'
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from '@material-ui/core/Link';
import {LanguageContext} from "../LanguageProvider";
import text from "../static/text.json"

const useStyles = makeStyles(theme => ({
    home: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    card: {
        background: '#ffffff',
        display: 'flex',
        width: '80%',
        flexDirection: 'row',
        alignItems: 'center',
        overflow: 'hidden'
    },
    section: {
        marginTop: "20px",
        marginBottom: "20px",
        background: "white",
        height: '100%',
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    textContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: "25px",
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    image: {
        width: '65%',
        margin: '25px',
        borderRadius: '2%'
    },
    leftCard: {
        display: "flex",
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "center",
        background: "#fdbf01",
        paddingTop: "5%",
        paddingBottom: "5%",
        [theme.breakpoints.down("md")]: {
            background: "white",
            color: "black"
        },
        width: "50%",
    },
    rightCard: {
        display: "flex",
        margin: '20px',
        flexDirection: 'column',
        alignItems: "center",
        width: "50%",
    },
    whiteText: {
        color: "white"
    },
    button: {
        marginTop: "20px",
        background: '#fdbf01',
        width: '60%'
    },
    grow: {
        flex: 1,
    },
    title: {
        color: '#1b5c7f'
    },
    Link: {
        color: '#fdbf01',
        fontWeight: 'bold',
        textDecoration: 'underline'
    },
    conditionalWhite: {
        color: "white",
        [theme.breakpoints.down("md")]: {
            color: "black",
            borderColor: "black",
        }
    },
    textSpace: {
        paddingTop: '2%'
    }

}));


function Home() {
    const classes = useStyles();
    const language = React.useContext(LanguageContext)

    function scrollToBottom() {
        window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'})
    }

    return (
        <div className={classes.home}>
            <Paper className={classes.section} elevation={3}>
                <div className={classes.textContent}>
                    <Typography className={classes.title} variant={'h4'}>
                        {text.homePageSectionATitle[language]}
                    </Typography>
                    <Typography variant={'h6'}>
                        {text.homePageSectionATextA[language]}
                        <Link className={classes.Link}
                              href="tel:581-777-5903">{text.homePageSectionATextB[language]}</Link>
                        {text.homePageSectionATextC[language]}
                    </Typography>
                </div>
                <img className={classes.image} src={image_1}/>
            </Paper>
            <Paper className={classes.card} elevation={3}>
                <div className={classes.leftCard}>
                    <Typography className={classes.conditionalWhite} variant={'h3'} gutterBottom
                                align={'center'}>{text.homePageSectionBTitleA[language]}</Typography>
                    <hr color={"white"} className={classes.conditionalWhite} width={"80%"}/>
                    <Typography className={classes.conditionalWhite} variant={'h3'} gutterBottom
                                align={'center'}> {text.homePageSectionBTitleB[language]}</Typography>
                </div>
                <div className={classes.rightCard}>
                    <Typography variant={'h5'} gutterBottom
                                align={'justify'}> {text.homePageSectionBText[language]}</Typography>
                    <hr color={"black"} width={"80%"}/>
                    <Button className={classes.button} onClick={scrollToBottom} color='primary' variant='contained'>
                        <Typography variant={'h5'}>{text.contactUsButton[language]}</Typography></Button>
                </div>
            </Paper>
            <Paper className={classes.section}>
                <img className={classes.image} src={image_3}/>
                <div className={classes.textContent}>
                    <Typography align={'center'} className={classes.title} variant={'h4'}>
                        {text.homePageSectionCTitle[language]}
                    </Typography>
                    <hr width={'100%'} color={'black'}/>
                    <div className={classes.list}>
                        <Typography className={classes.textSpace} variant={'h5'}>
                            {text.homePageSectionCTextA[language]}
                        </Typography>
                        <Typography className={classes.textSpace} variant={'h5'}>
                            {text.homePageSectionCTextB[language]}
                        </Typography>
                        <Typography className={classes.textSpace} variant={'h5'}>
                            {text.homePageSectionCTextC[language]}
                        </Typography>
                        <Typography className={classes.textSpace} variant={"h5"}>
                            {text.homePageSectionCTextD[language]}
                        </Typography>
                        <Typography className={classes.textSpace} variant={"h5"}>
                            {text.homePageSectionCTextE[language]}
                        </Typography>
                        <Typography className={classes.textSpace} variant={"h5"}>
                            {text.homePageSectionCTextF[language]}
                        </Typography>
                        <Typography className={classes.textSpace} variant={"h5"}>
                            {text.homePageSectionCTextG[language]}
                        </Typography>
                        <Typography className={classes.textSpace} variant={"h5"}>
                            {text.homePageSectionCTextH[language]}
                        </Typography>
                    </div>
                </div>
            </Paper>
            <div className={classes.grow}/>
            <ContactForm/>
        </div>
    )
}

export default Home;
