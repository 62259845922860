import React from "react";
import {PopupWidget} from "react-calendly";
import {LanguageContext} from "../LanguageProvider";
import text from "../static/text.json"

function Calendy() {
    const language = React.useContext(LanguageContext)


    return (
        <div>
            <PopupWidget text={text.calendy[language]} color={'#4472c3'}
                         url="https://calendly.com/asavocat/meeting"/>
        </div>
    );
}


export default Calendy;