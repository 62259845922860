import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {LanguageContext} from "../LanguageProvider";
import text from "../static/text.json";


const useStyles = makeStyles({
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    section: {
        marginTop: "20px",
        marginBottom: "20px",
        height: '100%',
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '20px'
    },
    list: {
        width: "90%",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    title: {
        marginBottom: "10px"
    },
    header: {
        marginTop: "50px"
    },
    ruler: {
        marginBottom: "30px"
    },
    star: {
        marginTop: "30px"
    }
});

function Rates() {

    const classes = useStyles();
    const language = React.useContext(LanguageContext)


    return (
        <div className={classes.content}>
            <Typography className={classes.header} align={'center'} variant={'h3'}>
                {text.ratesPageTitle[language]}
            </Typography>
            <hr className={classes.ruler} color={"black"} width={"80%"}/>

            <Paper className={classes.section}>
                <Typography className={classes.title} align={'center'} variant={'h4'}>
                    {text.ratesSubtitleA[language]}
                </Typography>
                <div className={classes.list}>
                    <Typography variant={'h5'}>
                        {text.ratesSectionATextA[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionATextB[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionATextC[language]}
                    </Typography>
                    <Typography className={classes.star} variant={'h7'}>
                        {text.ratesSectionATextD[language]}
                    </Typography>
                </div>
            </Paper>

            <Paper className={classes.section}>
                <Typography className={classes.title} align={'center'} variant={'h4'}>
                    {text.ratesSubtitleB[language]}
                </Typography>
                <div className={classes.list}>
                    <Typography variant={'h5'}>
                        {text.ratesSectionATextA[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionATextB[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionATextC[language]}
                    </Typography>
                </div>
            </Paper>

            <Paper className={classes.section}>
                <Typography className={classes.title} align={'center'} variant={'h4'}>
                    {text.ratesSubtitleC[language]}
                </Typography>
                <div className={classes.list}>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextA[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextB[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextC[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextD[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextE[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextF[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextG[language]}
                    </Typography>
                </div>
            </Paper>

            <Paper className={classes.section}>
                <Typography className={classes.title} align={'center'} variant={'h4'}>
                    {text.ratesSubtitleD[language]}
                </Typography>
                <div className={classes.list}>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextA[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextB[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextC[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextD[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextE[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextF[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextG[language]}
                    </Typography>
                </div>
            </Paper>

            <Paper className={classes.section}>
                <Typography className={classes.title} align={'center'} variant={'h4'}>
                    {text.ratesSubtitleE[language]}
                </Typography>
                <div className={classes.list}>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextA[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextB[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextC[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextD[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextE[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextF[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextG[language]}
                    </Typography>
                </div>
            </Paper>

            <Paper className={classes.section}>
                <Typography className={classes.title} align={'center'} variant={'h4'}>
                    {text.ratesSubtitleF[language]}
                </Typography>
                <div className={classes.list}>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextA[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextB[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextC[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextD[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextE[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextF[language]}
                    </Typography>
                    <Typography variant={'h5'}>
                        {text.ratesSectionBTextG[language]}
                    </Typography>
                </div>
            </Paper>
        </div>)
}

export default Rates;