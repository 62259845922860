import './App.css';
import React from 'react';
import {createBrowserHistory} from 'history';
import Layout from "./views/Layout";
import Admin from "./views/admin/Admin";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import {Helmet} from "react-helmet";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import Home from "./views/Home";
import About from "./views/About";
import Service from "./views/Service";
import Resource from "./views/Resource";
import Contact from "./views/Contact";
import Payment from "./views/Payment";
import Rates from "./views/Rates"
import Webinar from "./views/Webinar"
import queryString from 'query-string'


import LanguageProvider from "./LanguageProvider";

const appHistory = createBrowserHistory();

const useStyles = makeStyles({
    root: {
        display: 'flex',
        minWidth: '800px',
        width: '100vw',
        background: '#ffffff',
    }
});


function App() {

    const classes = useStyles();

    const value = queryString.parse(window.location.search);
    const lang = value.lang === "en" ? value.lang : "fr";
    const [language, setLanguage] = React.useState(lang)

    const breakpoints = createBreakpoints({});
    const theme = createMuiTheme({
        breakpoints
    });

    React.useEffect(() => {
        const viewport = document.querySelector('meta[name="viewport"]');
        if (viewport) {
            viewport.content = 'initial-scale=1';
            viewport.content = 'width=device-width';
        }
    }, []);

    return (
        <MuiThemeProvider theme={theme}>
            <div className={classes.root}>
                <Router history={appHistory}>
                    <LanguageProvider value={language}>
                        <Helmet htmlAttributes={{lang: language}}>
                        </Helmet>
                        <Switch>
                            <Route path='/admin' component={Admin}/>
                            <Route exact path="/">
                                <Redirect to="/home"/>}
                            </Route>
                            <Route component={({match}) =>
                                <Layout setLanguage={setLanguage}>
                                    <Route exact path='/home' component={Home}/>
                                    <Route exact path='/about' component={About}/>
                                    <Route exact path='/services' component={Service}/>
                                    <Route exact path='/resources' component={Resource}/>
                                    <Route exact path='/contact' component={Contact}/>
                                    <Route exact path='/webinar' component={Webinar}/>
                                    <Route exact path='/tarifs' component={Rates}/>
                                    <Route exact path='/rates' component={Rates}/>
                                    <Route exact path='/billing' component={Payment}/>
                                    <Route exact path='/paiement' component={Payment}/>
                                </Layout>
                            }/>
                        </Switch>
                    </LanguageProvider>
                </Router>
            </div>
        </MuiThemeProvider>
    );
}

export default App;
